import dynamic from "next/dynamic"
import { Job } from "common/types/graphql"
import { usePageProps } from "common/hooks/data/usePageProps"
import { EditPageProps } from "pages/edit/index.page"
import { JobsBoardView } from "./JobsBoard.view"

export interface JobsBoardProps {
  jobs?: Job[]
}

const JobsBoardEdit = dynamic<JobsBoardProps>(() =>
  import("./JobsBoard.edit").then((mod) => mod.JobsBoardEdit),
)

export const JobsBoard = ({ jobs }: JobsBoardProps) => {
  const { editing } = usePageProps<EditPageProps>()
  const JobsBoardComponent = editing ? JobsBoardEdit : JobsBoardView

  return <JobsBoardComponent jobs={jobs} />
}
