import { JobsBoardProps } from "./JobsBoard"
import { useState } from "react"
import { ImagePlaceholder } from "common/components/ImagePlaceholder"
import { MapPinIcon } from "@heroicons/react/24/solid"
import { useTranslations } from "modules/i18n/hooks/useTranslations"
import clsx from "classnames"
import type { Maybe } from "common/types/graphql"
import { Heading } from "common/components/Heading"

const FALLBACK_JOB_URL = "#" // In case the job doesn't have a URL, we'll simply add an anchor tag and do nothing
const MAX_JOB_COUNT = 4

// Parses a ISO 8601 date string from jobboard.io's job object.
// This method returns a localized version of the date in the following format: "02 February 2024".
const parseDate = (date: string) => {
  return new Date(date).toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "long",
    year: "numeric",
    timeZone: "UTC",
  })
}

/**
 * Component for displaying a jobboard.io job listing image.
 * In case the image fails to load, it will display a placeholder.
 *
 * Note: Storybook storyshots do not cover the `onLoad` and `onError` `img` events, but we do have it listed in our
 * stories. Let's ignore Test coverage in this case.
 */
/* istanbul ignore next */
export const JobboardImage = ({ logoUrl }: { logoUrl?: Maybe<string> }) => {
  const [imageError, setImageError] = useState(false)

  return (
    <>
      <img
        src={logoUrl || ""}
        alt=""
        className={clsx("size-24 object-scale-down lg:size-40", { hidden: imageError })}
        onError={() => setImageError(true)}
        onLoad={() => setImageError(false)}
      />
      {imageError && (
        <div className="size-40">
          <ImagePlaceholder />
        </div>
      )}
    </>
  )
}

/**
 * Component for displaying job listings from jobboard.io.
 */
export const JobsBoardView = ({ jobs = [] }: JobsBoardProps) => {
  const t = useTranslations("blocks.jobs_board")

  if (jobs?.length === 0) {
    return null
  }

  return (
    <section className="mb-4" data-testid="jobs-board">
      <div className="mb-4 mt-12">
        <Heading level={2}>{t("jobs_board")}</Heading>
      </div>

      <div className="grid grid-cols-1 gap-4 sm:gap-6 md:grid-cols-2">
        {jobs
          .slice(0, MAX_JOB_COUNT)
          .map(({ title, url, logoUrl, location, expirationDate }, index) => (
            <div key={index} className="flex justify-between gap-3 border-t border-gray-200 pt-4">
              <a
                href={url || FALLBACK_JOB_URL}
                /* eslint-disable-next-line tailwindcss/no-custom-classname */
                className="jobs-board-listing group flex w-full items-start gap-6"
                target="_blank"
              >
                <section className="flex basis-3/4 flex-col items-start gap-2">
                  <span className="group-hover:text-indigo-600 group-hover:underline">
                    <Heading level={5}>{title}</Heading>
                  </span>

                  {expirationDate && (
                    <div className="font-sans-heading text-xs text-gray-500">
                      {t("applications_close")} {parseDate(expirationDate)}
                    </div>
                  )}

                  {location && (
                    <div className="flex items-center gap-1 font-sans-heading text-xs">
                      {/*  Note: the MapPinIcon SVG doesn't fill the entire space of the div, so we're giving it a slight negative margin */}
                      <MapPinIcon className="-ml-1 size-6" />
                      {location}
                    </div>
                  )}
                </section>

                <JobboardImage logoUrl={logoUrl} />
              </a>
            </div>
          ))}
      </div>
    </section>
  )
}
